import React, { useEffect } from "react";
import { graphql } from "gatsby";

// Previews
import { withPreview } from "gatsby-source-prismic";

// Context
import { PageTypeConsumer } from "../components/context/page-type";

// Components
import FullVideo from "../components/videos/full-video";

const CustomReelVideoContent = ({ data, setPageType, location }) => {
  useEffect(() => {
    setPageType("video");
  }, []);

  const allCustomReelVideos = data.prismicCustomReel.data.videos
    .filter(video => video.video.document !== null)
    .map(video => video.video.document.uid);

  const videoPositionInReel = allCustomReelVideos.indexOf(
    data.prismicVideo.uid
  );

  const numberOfVideosInReel = allCustomReelVideos.length;

  let nextVideoPositionInReel;
  let prevVideoPositionInReel;
  if (allCustomReelVideos !== -1) {
    // Video exists
    nextVideoPositionInReel = videoPositionInReel + 1;
    prevVideoPositionInReel = videoPositionInReel - 1;
  } else {
    nextVideoPositionInReel = false;
    prevVideoPositionInReel = false;
  }

  if (nextVideoPositionInReel >= numberOfVideosInReel) {
    nextVideoPositionInReel = 0;
  }
  if (prevVideoPositionInReel < 0) {
    prevVideoPositionInReel = numberOfVideosInReel - 1;
  }

  let nextVideoUid = false;
  let prevVideoUid = false;

  if (nextVideoPositionInReel !== false && prevVideoPositionInReel !== false) {
    nextVideoUid = allCustomReelVideos[nextVideoPositionInReel];
    prevVideoUid = allCustomReelVideos[prevVideoPositionInReel];
  } else {
    nextVideoUid = false;
    prevVideoUid = false;
  }

  return (
    <FullVideo
      videoDetails={data.prismicVideo.data}
      video={data.prismicVideo.data.vimeo_video.html}
      width={data.prismicVideo.data.vimeo_video.width}
      height={data.prismicVideo.data.vimeo_video.height}
      playerId={`single_custom_reel_video_${data.prismicVideo.prismicId}`}
      embed_url={data.prismicVideo.data.vimeo_video.embed_url}
      directorUid={
        data.prismicVideo.data.director.document !== null
          ? data.prismicVideo.data.director.document.uid
          : false
      }
      director={
        data.prismicVideo.data.director.document !== null
          ? data.prismicVideo.data.director.document.data.title.html
          : ""
      }
      brand={data.prismicVideo.data.brand.text}
      clip={data.prismicVideo.data.title.text}
      nextVideoUid={`/${data.prismicCustomReel.uid}/${nextVideoUid}`}
      prevVideoUid={`/${data.prismicCustomReel.uid}/${prevVideoUid}`}
      generateVideoLocation={`/${data.prismicCustomReel.uid}`}
      backButtonURL={`/${data.prismicCustomReel.uid}`}
    />
  );
};

const CustomReelVideo = ({ data, location }) => (
  <PageTypeConsumer>
    {({ setPageType }) => (
      <CustomReelVideoContent
        setPageType={setPageType}
        data={data}
        location={location}
      />
    )}
  </PageTypeConsumer>
);

export default withPreview(CustomReelVideo);

export const query = graphql`
  query CustomReelVideo($uid: String!, $reelUid: String!) {
    prismicVideo(uid: { eq: $uid }) {
      uid
      prismicId
      data {
        title {
          text
        }
        brand {
          text
        }
        director {
          document {
            ... on PrismicDirector {
              id
              uid
              data {
                title {
                  html
                }
                videos {
                  video {
                    uid
                  }
                }
              }
            }
          }
        }
        vimeo_video {
          width
          title
          description
          embed_url
          height
          html
        }
      }
    }
    prismicCustomReel(uid: { eq: $reelUid }) {
      uid
      prismicId
      data {
        videos {
          video {
            document {
              ... on PrismicVideo {
                id
                uid
                data {
                  title {
                    text
                  }
                  vimeo_video {
                    html
                    embed_url
                    width
                    description
                    title
                    height
                  }
                  vimeo_mp4_file {
                    url
                  }
                  title {
                    text
                  }
                  brand {
                    text
                  }
                  director {
                    uid
                    document {
                      ... on PrismicDirector {
                        id
                        uid
                        data {
                          title {
                            text
                          }
                        }
                      }
                    }
                  }
                  thumbnail {
                    alt
                    fluid(imgixParams: { w: 2000 }) {
                      srcWebp
                      srcSetWebp
                    }
                  }
                }
              }
            }
          }
        }
        title {
          html
          text
        }
      }
    }
  }
`;
